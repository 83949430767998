
*,
::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #282c34;
}

svg {
  box-sizing: content-box;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
  color: #0c212d;
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}