.main-footer {
  display: flex;
  align-items: center;
  background-color: #333;

  .box-left,
  .box-center,
  .box-right {
    flex: 1;
  }

  .box-center {
    text-align: center;
  }

  .box-right {
    text-align: right;
  }

  @media screen and (max-width: 800px) {
    padding: 20px;

    .box-left {
      display: none;
    }

    .box-center {
      text-align: left;
    }
  }
}
