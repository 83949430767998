@import url("./partials/reset.scss");
@import url("./partials/spaces.scss");

.ant-card {
  border: 1px solid #d9d9d9;
}

.ant-table-content {
  user-select: none;
}

.ant-modal-close.ant-modal-close {
  width: 30px;
  height: 30px;

  .ant-modal-close-x {
    .ant-modal-close-icon {
      font-size: 22px;
    }
  }
}


.ant-table-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  @media screen and (max-width: 1100px) {
    max-width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.MuiDialog-paper {
  overflow: visible !important;
  min-width: 600px;

  @media screen and (max-width:700px) {
    min-width: initial;

  }
}

.devices-row {
  @media screen and (max-width: 750px) {
    flex-flow: wrap;

    &__content {
      flex-basis: 100%;
      margin-bottom: 15px;
    }

    .ant-list-item-action {
      margin-left: 0 !important;
    }
  }
}

.ant-picker-time-panel-cell.ant-picker-time-panel-cell-disabled {
  display: none;
}

@import url("./partials/typography.scss");
@import url("./partials/main-header.scss");
@import url("./partials/issue-management.scss");
@import url("./partials/steps.scss");
@import url("./partials/pagination.scss");
@import url("./partials/tag.scss");
@import url("./partials/footer.scss");
@import url("./partials/dark.scss");
@import url("./partials/notification.scss");
@import url("./partials/calendar.scss");
@import url("./partials/tooltip.scss");
@import url("./partials/tables.scss");
@import url("./partials/settlements_list.scss");
@import url("./partials/task-color-picker.scss");
@import url("./partials/uploader-grid.scss");
