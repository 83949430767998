.ant-upload-wrapper {
  .ant-upload-list-picture {
    margin-top: 10px;
    display: flex;
    flex-flow: wrap;
    gap: 10px;
    &::before,
    &::after {
      display: none;
    }
    .ant-upload-list-item-name {
      display: none;
    }
    .ant-upload-list-item-actions {
      margin-left: 10px;
    }
  }
}
