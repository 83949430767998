.dark-mode {
  a {
    color: rgba(255, 255, 255, 0.85);
  }

  .ant-table-content {
    a {
      color: #1668dc;
    }
  }

  .ant-input-affix-wrapper,
  .ant-form-item-control-input-content {
    background-color: #141414;

    input {
      background-color: #141414;
      color: rgba(255, 255, 255, 0.85);
      border-color: #424242;
    }
  }

  .billing-tag-empty {
    background-color: #fff;
    border-color: #fff;

    span {
      color: #000;
    }
  }

  .task-badge {
    span {
      color: #000;
    }

    &.task-badge--black {
      span {
        color: #fff;
      }
    }
  }
  .ant-upload.ant-upload-select {
    span {
      color: #fff;
    }
  }
}

.checklist {
  .ant-badge-count {
    color: #fff;
  }
}
