.ant-tag {
  &-blue {
    color: #fff;
    background-color: #0958d9;
    border-color: #0958d9;
  }

  &-orange {
    color: #000;
    background-color: #ffcc00;
    border-color: #ffcc00;
  }

  &-green {
    color: #fff;
    background-color: #339900;
    border-color: #339900;
  }

  &-red {
    color: #fff;
    background-color: #cc3300;
    border-color: #cc3300;
  }
}
