.login-panel {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-statistic-content{
    font-size: 14px;
    line-height: 20px;
  }
}
