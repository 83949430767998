.custom-pagination {
  .ant-pagination-simple-pager.ant-pagination-simple-pager {
    input {
      pointer-events: none;
      color: #1677ff;
      font-weight: 600;
      background-color: #ffffff;
      border-color: #1677ff;
    }
  }
}

.ant-pagination-options {
  .ant-select-selection-search-input {
    display: none;
  }
}
