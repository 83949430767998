.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: #333;

  .ant-menu-horizontal {
    background-color: #333;

    .ant-menu-item-selected {
      background-color: #f6a400;
    }
  }

  .main-menu {
    flex: 1;

    @media screen and (max-width: 1100px) {
      max-width: 500px;
    }

    @media screen and (max-width: 1100px) {
      max-width: 100px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 50px;
    line-height: 50px;

    .ant-menu-item {
      padding: 0 10px;
    }

    .ant-menu-title-content {
      font-size: 12px;
      line-height: 20px;
    }

    .username.username {
      font-size: 14px;
    }
  }
}

.username.username {
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0 !important;
  padding: 10px 20px !important;
  span {
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    color: #000;
  }
}

.dark-mode {
  .username.username {
    span {
      color: #fff;
    }
  }
}
