.light-mode {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      padding: 10px 15px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.85);
      color: rgba(0, 0, 0, 0.85);
    }
  }

}