.ant-steps {
  .ant-steps-item-active,
  .ant-steps-item-finish {
    svg {
      fill: #ffff;
    }

    &.status_new {
      .ant-steps-item-icon {
        background-color: #0958d9;
        border-color: #0958d9;
      }
    }

    &.status_service {
      .ant-steps-item-description {
        white-space: nowrap;
      }

      .ant-steps-item-icon {
        background-color: #faad14;
        border-color: #faad14;
      }
    }

    &.status_in_progress {
      .ant-steps-item-icon {
        background-color: #d46b08;
        border-color: #d46b08;
      }
    }

    &.status_completed {
      .ant-steps-item-icon {
        background-color: #389e0d;
        border-color: #389e0d;
      }
    }

    &.status_cancelled {
      .ant-steps-item-icon {
        background-color: #ff4d4f;
        border-color: #ff4d4f;
      }
    }
  }
}
