.issue-management {
  display: flex;
  justify-content: space-between;

  &__col {
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  .buttons-group {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin: 0 -5px 10px;

    button {
      margin: 0 5px 10px;
    }

    span:last-child,
    button:last-child {
      margin-left: auto;
    }
  }

  .issue {
    position: relative;
    transition: 0.2s;
    user-select: none;

    .ant-card-body {
      padding: 12px 50px 12px 12px;
    }

    p {
      margin: 0;
      font-size: 13px;
    }

    .btn-preview {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    .btn-dropdown {
      position: absolute;
      right: 10px;
      bottom: 50px;
    }

    a:hover {
      text-decoration: underline;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }

    &:hover {
      transform: scale(1.02);
    }

    &.new {
      border-color: #0958d9;

      &:hover {
        box-shadow: 2px 2px 20px rgba(9, 88, 217, 0.6);
      }

      .ant-card-head {
        background-color: #0958d9;
        color: #fff;

        a {
          color: inherit;
        }
      }

      &.active {
        border-color: rgb(135, 208, 104);

        &:hover {
          box-shadow: 2px 2px 20px rgba(135, 208, 104, 0.6);
        }

        .ant-card-head {
          background-color: rgb(135, 208, 104);
          color: #000;
        }
      }
    }

    &.in_progress {
      border-color: rgb(255, 204, 0);

      &:hover {
        box-shadow: 2px 2px 20px rgba(255, 204, 0, 0.6);
      }

      .ant-card-head {
        background-color: #ffcc00;
        color: #000;
      }

      &.active {
        border-color: #ffcc00;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    flex-flow: wrap;

    &__col {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
}