.text-ellipsis {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  display: inline-block;
  word-break: break-word;
}

.text-center {
  text-align: center;
}

.text-up {
  text-transform: uppercase;
}

.ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ant-modal-title {
  font-size: 20px !important;
}

.ant-modal-body {
  font-size: 17px !important;
}

.ant-form-item-control-input {
  input {
    font-weight: 600;
  }
}

.ant-picker-today-btn,
.ant-picker-now-btn {
  font-weight: 600;
  color: #1677ff;
}

.ant-picker-content {
  thead {
    tr {
      th {
        font-weight: 700 !important;
      }
    }
  }
}

.ant-picker-ok {
  .ant-btn {
    padding: 5px 20px;
    height: initial;
  }
}

textarea {
  resize: none !important;
}

.ant-input-number-affix-wrapper,
.ant-input-number {
  width: 100%;
}

.ant-input,
.ant-picker,
.ant-input-number,
.ant-select-selector.ant-select-selector.ant-select-selector,
.ant-input-affix-wrapper,
.ant-btn-default {
  border-color: #939191;
}

.ant-list-item.line-through {
  text-decoration: line-through;
}

.ant-radio-group {
  display: inline-flex;

  .ant-radio-button-wrapper-in-form-item {
    flex: 1;
    text-align: center;
  }
}

.urgent-radio {
  .ant-radio-button-wrapper:nth-child(2) {

    &.ant-radio-button-wrapper-checked {
      background-color: #ff4d4f;
      border-color: #ff4d4f;
    }
  }
}

.billing-tag-empty {
  background-color: #000;
  border-color: #000;

  span {
    color: #fff;
  }
}


.text-nowrap {
  white-space: nowrap;
}