.task-color-picker {
  display: flex;
  flex-flow: wrap;
  gap: 10px;
  margin: 10px 0 40px;

  &__button {
    padding: 6px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    transition: 0.2s;
    border: 3px solid transparent;
    &--active {
      border-color: #000;
      span {
        font-weight: 600;
      }
    }

    &--red {
      background-color: #ff0000;
    }

    &--orange {
      background-color: #ffae00;
    }

    &--blue {
      background-color: #0089ff;
    }

    &--black {
      background-color: #000000;

      .task-color-picker__count {
        border-color: #fff;
        color: #fff;
      }
      &.task-color-picker__button--active {
        border-color: #ff0000;
      }
    }
  }

  &__count {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 50%;
    line-height: 20px;
    margin-bottom: 10px;
  }

  &__name {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
  }
}

.dark-mode {
  .task-color-picker {
    &__button {
      &--active {
        border-color: #fff;
      }
      &--black {
        &.task-color-picker__button--active {
          border-color: #fff;
        }
      }
    }
  }
}
