.issues-table {
  .ant-table-tbody {
    tr {
      td {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          font-weight: 700;
        }
      }
    }
  }
}

.ant-table-thead.ant-table-thead.ant-table-thead {
  th,
  td {
    background: #e5e4e4;
  }
}
.ant-table-row-selected.ant-table-row-selected {
  .ant-table-cell.ant-table-cell.ant-table-cell {
    background: #f8edb6;
  }
}

.dark-mode {
  .ant-table-thead.ant-table-thead.ant-table-thead {
    th,
    td {
      background: #000;
    }
  }
  .ant-table-row-selected.ant-table-row-selected {
    .ant-table-cell.ant-table-cell.ant-table-cell {
      background: #4e412c;
    }
  }
}
