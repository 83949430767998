.settlement-row {
  @media screen and (max-width: 992px) {
    flex-flow: wrap-reverse;
  }
}

#worker_issues {
  overflow: auto;
  max-height: calc(100vh - 40px);
  white-space: nowrap;

  .infinite-scroll-component__outerdiv {
    min-width: max-content;
  }
}