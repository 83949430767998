@import url('https://fonts.googleapis.com/css2?family=Playwrite+NG+Modern&display=swap');

.calendar-panel {
  padding: 10px;
  height: 60px;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

.MuiTypography-caption {
  font-weight: 600 !important;
}

.MuiBox-root+div {
  display: none;
}

.MuiAvatar-root.MuiAvatar-circular {
  pointer-events: none
}

.rs__time {
  span {
    font-weight: 600;
  }
}

.rs__cell .MuiAvatar-root.MuiAvatar-circular {
  left: 50%;
  transform: translateX(-50%);

  &.MuiAvatar-colorDefault {
    background-color: #000;
  }
}

.rs__view_navigator {
  display: none !important;

  &>div {
    display: block;
    width: 100%;
    flex: 1;
  }
}

[data-testid="rs-wrapper"] {
  padding: 0 20px 20px;

  background-color: #fff;
}


[data-testid="grid"] {
  border: 1px solid #000;

  div:first-child {
    border-bottom-width: 1px;
    border-bottom-color: #000;

    p {
      font-size: 1.2rem;
    }
  }
}

.ant-modal-content {
  .MuiPaper-elevation {
    justify-content: center !important;

    &>div {
      &>button {
        font-size: 1.5rem;

        svg {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 599.95px) {
  .rs__cell.rs__header.rs__time {
    transform: rotate(-180deg);
    border-width: 1px 0px 0px 1px;
  }
}

.day-calendar-modal {
  &__sticky-box {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .rs__header.rs__cell {
    min-height: 55px;

    &>div {
      height: 100%;
      width: initial !important;

      &:first-child {
        position: relative;

        p:nth-child(2) {
          font-size: 0 !important;
          line-height: 0 !important;

          &::after {
            content: var(--day-value);
            font-size: 12px;
            line-height: 14px;
          }
        }

        &::after {
          content: "Czas: " var(--time-value);
          font-size: 17px;
          line-height: 24px;
          position: absolute;
          right: 10px;
          top: calc(50% - 12px);
          min-width: 70px;
        }
      }

    }
  }

  [data-testid="grid"] {
    border: none !important;
    user-select: none;

    &>div:first-child {
      border-radius: 8px 8px 0 0;
      border-bottom-width: 0;
      border-bottom-color: #e0e0e0;
    }

    &>div:last-child {
      border-radius: 0 0 8px 8px;
      border-bottom-width: 0;

      span {
        &:last-child {
          border-radius: 0 0 8px 0;
        }

        &:nth-last-child(2) {
          border-radius: 0 0 0 8px;
        }
      }
    }
  }

  [data-testid="rs-wrapper"] {

    *,
    *::before,
    *::after {

      box-sizing: content-box;
    }

    .MuiButtonBase-root {
      box-sizing: border-box;

    }

    @media screen and (max-width:992px) {
      padding: 0;
    }
  }

  .rs__cell .MuiButtonBase-root.MuiButton-textPrimary {
    &::before {
      display: none;
    }

    &:hover {
      &::after {
        display: none;
      }
    }
  }

  div[data-testid="grid"] {
    .rs__cell.rs__today_cell {
      &>div:not(.rs__event__item) {
        width: 63px;
        z-index: 100;
      }
    }

    div:first-child {
      background-color: rgba(0, 0, 0, 0.1);

      .rs__cell:first-child {
        background-color: transparent;
      }

    }
  }

}

.issue_month_calendar {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 20px;
    position: relative;

    .ant-select {
      z-index: 2;
      position: absolute;
      top: calc(100% + 22px);
    }

    @media screen and (max-width: 1000px) {
      gap: 10px;
      position: static;

      .ant-select {
        position: relative;
      }
    }
  }
}

.calendar-issue-container {
  position: relative;
  margin: 20px;

  @media screen and (max-width: 800px) {
    margin: 5px;
  }

  .calendar-issue-count {
    position: absolute;
    right: 22px;
    top: 15px;
    z-index: 12;

    p {
      margin: 5px 0;
    }
  }

  .calendar-issue-select {
    position: absolute;
    left: 20px;
    top: 23px;
    z-index: 12;
  }

  @media screen and (max-width:900px) {
    .calendar-issue-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .calendar-issue-count {
      position: static;
      text-align: right;
    }

    .calendar-issue-select {
      position: static;
      text-align: right;
    }
  }
}

.month_calendar {

  [data-testid="rs-wrapper"] {
    &>.MuiPaper-elevation {
      justify-content: center !important;
    }
  }

  [data-testid="date-navigator"] {
    justify-content: center !important;
    margin: 20px 0;

    svg {
      font-size: 2rem;

      @media screen and (max-width: 800px) {
        font-size: 1.5rem;

      }
    }

    [aria-label="selected month"] {

      font-size: 0;
      line-height: 0;
      padding: 0 10px !important;

      &::after {
        font-size: 2rem;
        font-family: "Playwrite NG Modern", cursive;
        font-optical-sizing: auto;

        content: var(--month-value);

        @media screen and (max-width: 800px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  [data-testid="grid"] {
    &>div:first-child {
      p {
        background-color: #e5e4e4;
      }
    }

    &>div {
      p {
        font-size: 0;
        line-height: 0;

        &::before {
          font-size: 16px;
          line-height: 26px;
          font-weight: 600;
        }
      }

      p:nth-child(1) {
        &::before {
          content: 'Poniedziałek';
        }
      }

      p:nth-child(2) {
        &::before {
          content: 'Wtorek';
        }
      }

      p:nth-child(3) {
        &::before {
          content: 'Środa';
        }
      }

      p:nth-child(4) {
        &::before {
          content: 'Czwartek';
        }
      }

      p:nth-child(5) {
        &::before {
          content: 'Piątek';
        }
      }

      p:nth-child(6) {
        &::before {
          content: 'Sobota';
        }
      }

      p:nth-child(7) {
        &::before {
          content: 'Niedziela';
        }
      }

      @media screen and (max-width: 800px) {
        p:nth-child(1) {
          &::before {
            content: 'Pon.';
          }
        }

        p:nth-child(2) {
          &::before {
            content: 'Wt.';
          }
        }

        p:nth-child(3) {
          &::before {
            content: 'Śr.';
          }
        }

        p:nth-child(4) {
          &::before {
            content: 'Czw.';
          }
        }

        p:nth-child(5) {
          &::before {
            content: 'Pt.';
          }
        }

        p:nth-child(6) {
          &::before {
            content: 'Sb.';
          }
        }

        p:nth-child(7) {
          &::before {
            content: 'Nd.';
          }
        }
      }
    }
  }

  .rs__cell {
    display: flex;
    flex-direction: column;
    align-items: center;

    .month-cell {
      cursor: pointer;
      height: 100%;
      padding: 10px;
      text-align: center;
      width: 100%;

      &.weekend {
        background-color: #ffefea;
      }

      &.holiday {
        background-color: #f0cabe;
      }

      &.today {
        background-color: #f6a400;
      }

      &.disabled {
        .month-cell__day {
          opacity: 0.3;
        }

        +.MuiAvatar-root+.rs__multi_day {
          opacity: 0.3;
        }
      }

      &__day {
        display: inline-block;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;

        @media screen and (max-width:992px) {
          font-size: 15px;
          line-height: 26px;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0);
        transition: 0.2s;
      }

      &:hover {
        &::before {
          background-color: rgba(246, 164, 0, .6);
        }

        &::after {
          content: "+";
          color: #fff;
          font-size: 35px;
          line-height: 40px;
          position: absolute;
          z-index: 101;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%)
        }
      }
    }

    .rs__multi_day {
      margin: 0 auto;
      top: 50px !important;
      max-width: 80%;

      button {
        text-align: center;
        padding: 2%;

        h6 {
          font-size: 14px !important;
          white-space: nowrap;

          @media screen and (max-width:1200px) {
            font-size: 12px !important;
          }

          @media screen and (max-width:992px) {
            font-size: 10px !important;
          }
        }
      }
    }

    .MuiAvatar-root {
      display: none;
    }
  }

  &.ant-modal {
    .rs__cell {
      .rs__multi_day {
        top: 40px !important;
      }
    }
  }
}

.MuiPopover-paper {
  .MuiBox-root {
    user-select: none;
    background-color: #fff !important;

    .MuiSvgIcon-root {
      color: #000;
    }
  }
}

.dark-mode {
  .month_calendar {
    .rs__cell {
      .month-cell {

        &.weekend {
          background-color: #fc744b;
        }

        &.holiday {
          background-color: #fc3c01;
        }

        &.today {
          background-color: #f6b000;
        }

        &__day {
          color: #fff;
        }
      }
    }
  }

  .rs__root {
    background-color: #333;
    color: #fff;
  }

  .rs__cell {
    border-color: #d9d9d9;
  }

  .rs__event {
    background-color: #1f1f1f;
    color: #fff;
  }

  .rs__header {
    background-color: #1f1f1f;
    color: #fff;
  }

  .calendar-issue-container {
    background-color: #1f1f1f;

  }

  [data-testid="rs-wrapper"] {
    padding: 0 20px 20px;

    background-color: #1f1f1f;

    &>div {
      background-color: #1f1f1f;
    }

  }

  [data-testid="grid"] {
    border: 1px solid #d9d9d9;
    background-color: #141414 !important;

    &>div:first-child {
      p {
        background-color: #141414;
      }
    }

    .rs__cell {
      background-color: #141414;
      border-color: #d9d9d9;

      p {
        color: #fff;
      }
    }
  }

  .MuiPaper-elevation {
    &>div {
      &>button {
        color: #fff;

        svg {
          fill: #fff;
        }
      }
    }
  }

  .calendar-issue-container {
    .calendar-issue-count {
      color: #fff;
    }
  }

  .MuiPopover-paper {
    .MuiBox-root {
      background-color: #000 !important;

      .MuiSvgIcon-root {
        color: #fff;
      }

      a {
        color: #fff !important;
      }
    }
  }
}